import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import CookieConsent from "react-cookie-consent";
import axios from 'axios';
import {BaseURL, Token} from '../utils/utils';

const Footer = ({language}: {language: string}) => {
  const [content, setContent] = useState([])
  useEffect(() => {
    async function fetchPage(){
      const content = await axios.get(BaseURL + '/cockpit/api/collections/get/services?token='+Token+'&populate=3&lang='+language);
      setContent(content.data.entries)
    }
    fetchPage()
  }, [language])
  return<footer>
  <div className="container container-fluid">
    <div className="row">
      <div className="col-xs-12 center-xs start-sm col-sm-6 col-md-4 col-lg-3">
        <Link to='/'>
          <h3>
            {(language==='de')||(language==='ch') ? "Unternehmen" : ""}
            {language==='en' ? "Company" : ""}
            {language==='fr' ? "Carrière" : ""}
            {language==='it' ? "Carriera" : ""}
          </h3>
        </Link>
          <ul className="hide-xs">
            <li><Link to="/#services">
            {(language==='de')||(language==='ch') ? "Unternehmen" : ""}
            {language==='en' ? "Company" : ""}
            {language==='fr' ? "Carrière" : ""}
            {language==='it' ? "Carriera" : ""}
            </Link></li>
            <li><Link to="/#references">
            {(language==='de')||(language==='ch') ? "Referenzen" : ""}
            {language==='en' ? "References" : ""}
            {language==='fr' ? "Créance" : ""}
            {language==='it' ? "Credenziali" : ""}</Link></li>
            <li><Link to="/#locations">
            {(language==='de')||(language==='ch') ? "Standorte" : ""}
            {language==='en' ? "Locations" : ""}
            {language==='fr' ? "Emplacement" : ""}
            {language==='it' ? "Posizione" : ""}</Link></li>
            <li><Link to="/#news">
            {(language==='de')||(language==='ch') ? "News" : ""}
            {language==='en' ? "News" : ""}
            {language==='fr' ? "Nouvelles" : ""}
            {language==='it' ? "Notizie" : ""}</Link></li>
            <li><Link to="/#contact">            {(language==='de')||(language==='ch') ? "Kontakt" : ""}
            {language==='en' ? "Contact" : ""}
            {language==='fr' ? "Contact" : ""}
            {language==='it' ? "Contatto" : ""}</Link></li>
          </ul>
      </div>
        {content.map((service: any) => 
      <div key={service.title} className="col-xs-12 center-xs start-sm col-sm-6 col-md-4 col-lg-3">
          <Link to={service.link}>
            <h3>{service.title}</h3>
          </Link>
          <ul className="hide-xs">
            {service.subservices.map((subservice: any) => <li key={subservice.title}><Link  to={'/'+service.link+"#"+subservice.hashlink}>{subservice.title}</Link></li>)}
          </ul>
      </div>
        )}
      <div className="col-xs-12 center-xs start-sm col-sm-6 col-md-4 col-lg-3">
        <Link to='/shops'>
          <h3>
            Webshops
          </h3>
        </Link>
        <ul className="hide-xs">
          <li>
            <a target="_blank" href="https://www.berrybase.de" rel="noopener noreferrer">Berrybase-Shop</a>
          </li>
          <li>
            <a target="_blank" href="https://et.sertronics-shop.de" rel="noopener noreferrer">
            {(language==='de')||(language==='ch') ? "Ersatzteile-Shop" : ""}
            {language==='en' ? "Spare Parts Shop" : ""}
            {language==='fr' ? "Pièces de rechange" : ""}
            {language==='it' ? "Pezzi di ricambio" : ""}
            </a>
          </li>
        </ul>
      </div>
      <div className="col-xs-12 center-xs start-sm col-sm-6 col-md-4 col-lg-3">
        <a href="https://jobs.sertronics.de">
          <h3>
            {(language==='de')||(language==='ch') ? "Karriere" : ""}
            {language==='en' ? "Career" : ""}
            {language==='fr' ? "Carrière" : ""}
            {language==='it' ? "Carriera" : ""}
          </h3>
        </a>
        {/*<Link to='/career'>
          <h3>
            {(language==='de')||(language==='ch') ? "Karriere" : ""}
            {language==='en' ? "Career" : ""}
            {language==='fr' ? "Carrière" : ""}
            {language==='it' ? "Carriera" : ""}
          </h3>
        </Link>*/}
        <ul className="hide-xs">
          <li>
            <a href="https://jobs.sertronics.de/#jobs">
              {(language==='de')||(language==='ch') ? "Stellenanzeigen" : ""}
              {language==='en' ? "Open Positions" : ""}
              {language==='fr' ? "Poste Libre" : ""}
              {language==='it' ? "Posizione Aperta" : ""}
            </a>
          </li>
          {/*
          <li>
            <Link to="/career#positions">
            {(language==='de')||(language==='ch') ? "Stellenanzeigen" : ""}
            {language==='en' ? "Open Positions" : ""}
            {language==='fr' ? "Poste Libre" : ""}
            {language==='it' ? "Posizione Aperta" : ""}
            </Link>
          </li>*/}
        </ul>
      </div>
      <div className="col-xs-12 center-xs start-sm col-sm-6 col-md-4 col-lg-3">
        <Link to='/help-point'>
          <h3>
            {(language==='de')||(language==='ch') ? "Help Point" : ""}
            {language==='en' ? "Help Point" : ""}
            {language==='fr' ? "Help Point" : ""}
          </h3>
        </Link>
        <ul className="hide-xs">
          <li>
            <Link to="/help-point#repair_booking">
            {(language==='de')||(language==='ch') ? "Reparaturannahme" : ""}
            {language==='en' ? "Repair Booking" : ""}
            {language==='fr' ? "Réparation d'appareils" : ""}
            </Link>
          </li>
          <li>
            <Link to="/help-point#repair_info">
            {(language==='de')||(language==='ch') ? "Reparaturauskunft" : ""}
            {language==='en' ? "Repair Tracking" : ""}
            {language==='fr' ? "Informations de réparation" : ""}
            </Link>
          </li>
          <li>
            <Link to="/help-point#repair">
            {(language==='de')||(language==='ch') ? "Preisliste" : ""}
            {language==='en' ? "Price List" : ""}
            {language==='fr' ? "Liste de prix" : ""}
            </Link>
          </li>
          <li>
            <Link to="/help-point#retour">
            {(language==='de')||(language==='ch') ? "Retourenportal" : ""}
            {language==='en' ? "Return portal" : ""}
            </Link>
          </li>
        </ul>
      </div>
      <div className="col-xs-12 center-xs start-sm col-sm-6 col-md-4 col-lg-3">
        <h3>
          {(language==='de')||(language==='ch') ? "Rechtliches" : ""}
          {language==='en' ? "Legal" : ""}
          {language==='fr' ? "Loi" : ""}
          {language==='it' ? "Legge" : ""}
        </h3>
        <ul>
          <li>
            <Link to='/terms'>
              {(language==='de')||(language==='ch') ? "AGB" : ""}
              {language==='en' ? "Terms and Conditions" : ""}
              {language==='fr' ? "Conditions" : ""}
              {language==='it' ? "condizioni" : ""}
            </Link>
          </li>
          <li>
            <Link to='/disclaimer'>
            {(language==='de')||(language==='ch') ? "Impressum" : ""}
            {language==='en' ? "Disclaimer" : ""}
            {language==='fr' ? "Empreinte" : ""}
            {language==='it' ? "Impronta" : ""}
            </Link>
          </li>
          <li>
            <Link to='/privacy'>
            {(language==='de')||(language==='ch') ? "Datenschutz" : ""}
            {language==='en' ? "Privacy Statement" : ""}
            {language==='fr' ? "Politique de confidentialité" : ""}
            {language==='it' ? "Privacy Policy" : ""}
            </Link>
          </li>
        </ul>
      </div>
    </div>
    <hr/>
    <div className="row copy-right-row">
      <div className="col-xs-12 center-xs">© {(new Date()).getFullYear()} SERTRONICS {(language === 'de')||(language === 'en') ? "GMBH" : "AG"}</div>
    </div>
  </div>
  <CookieConsent
	location="bottom"
	buttonText="Ok"
	buttonClasses="lang-btn-selected"
	cookieName="SertronicsCookieConsent"
	style={{ background: "#2B373B" }}
	buttonStyle={{ background: "#0E6EB6", borderRadius: "4px", color: "#fff", fontSize: "13px" }}
	expires={150}
  >
	{(language==='de')||(language==='ch') ? "Diese Seite verwendet Cookies, um Ihnen die bestmögliche Erfahrung mit unserer Webseite bieten zu können. " : "" }
	{language==='en' ? "This website uses cookies to enhance the user experience. " : ""}
	<Link to='/privacy'><u>{(language==='de')||(language==='ch') ? "Mehr Informationen" : "More informations"}</u></Link>
  </CookieConsent>
</footer>}
export default Footer