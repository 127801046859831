import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Hero} from '../components/Hero';
import {BaseURL, Token, hashScrollHandler} from '../utils/utils';

const dehydrated = {
    hero: [],
	title: '',
}

const NotFound = ({language}: {language: string}) => {
	const [data, setData] = useState(dehydrated)
	useEffect(() => {
		async function fetchPage(){
			const content = await axios.get(BaseURL + '/cockpit/api/singletons/get/not_found?token='+Token+'&lang='+language+'&populate=3');
			setData({...content.data})
			hashScrollHandler()
		}
		fetchPage()
	}, [language])
	useEffect(() => {hashScrollHandler()})
	return(<div className="error404-page">
		<Hero data={data.hero} btn={true} language={language}/>
	</div>)
}

export default NotFound;