import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Hero} from '../components/Hero';
import MainCard from '../components/MainCard';
import Testimonial, {ITestimonial} from '../components/Testimonal';
import {BaseURL, Token, hashScrollHandler} from '../utils/utils';
import clock from '../images/clock.svg';
import call from '../images/call.svg';
import mail from '../images/mail.svg';
import back from '../images/back.svg';
import next from '../images/next.svg';
import poi from '../images/poi.svg';

const dehydrated = {
  hero: [],
  heading_1: '',
  paragraph_1: '',
  img_1: '',
  cards: [],
  heading_2: '',
  img_2: [],
  paragraph_2: '',
  locations:[{name: "", description: "", openingHours: "", mail: "", tel: "", maps: "", img: {path: ""}}],
  articles: [],
  testimonials: [],
  onlineservices: []
} as any

// Custom hook for window width (used for news article splicing)
const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return width;
}

const Article = ({title, img, text, ...rest}: {title: string, img: {path: string}, text: string, rest: any}) => <> {title && img && text ? <div  className="col-xs-12 col-md-6">
<h2>{title}</h2>
<p><img src={BaseURL + img.path} alt="" style={{float: "left", marginRight: 20, marginBottom: 5, maxWidth: 96}}/> {text}</p>
</div> : null}</>

const sendData = async (e: any, language: string) => {
  e.preventDefault()
  const name = (document.getElementById("form-name") as HTMLInputElement).value
  const tel = (document.getElementById("form-tel") as HTMLInputElement).value
  const email = (document.getElementById("form-email") as HTMLInputElement).value
  const content = (document.getElementById("form-content") as HTMLTextAreaElement).value
  const domain = ((language==='de')||(language ==='en')) ? "de" : 'ch'
  axios.post('mail.php', {name: name, tel: tel, email: email, content: content, domain: domain})
  const form = document.getElementById('contact-form') as HTMLFormElement;
  form.innerHTML = (language==='de')||(language==='ch') ? "<h3>Anfrage gesendet.<h3>" : (language === 'en'? "<h3>Message sent.</h3>" : (language === 'fr' ? "<h3>Message envoyé.</h3>" : "<h3>Messaggio inviato.</h3>"))
}

const LandingPage = ({language}: {language: string}) => {
  const [articleIdx, setArticleIdx] = useState(0);
  const [data, setData] = useState(dehydrated);
  const [locationIdx, setLocationIdx] = useState(0);
  const width = useWindowWidth();
  useEffect(() => {
    async function fetchPage(){
      const content = await axios.get(BaseURL + '/cockpit/api/singletons/get/landing_page?token='+Token+'&lang='+language+'&populate=3');
      const locations = await axios.get(BaseURL + '/cockpit/api/collections/get/locations?token='+Token+'&lang='+language);
      const articles = await axios.get(BaseURL + '/cockpit/api/collections/get/articles?token='+Token+'&lang='+language);
      const services = await axios.get(BaseURL + '/cockpit/api/collections/get/services?token='+Token+'&lang='+language);
      const onlineservices = await axios.get(BaseURL + '/cockpit/api/collections/get/onlineservices?token='+Token+'&lang='+language);
      const testimonials = await axios.get(BaseURL + '/cockpit/api/collections/get/testimonials?token='+Token+'&lang='+language);
      setData({
        ...content.data,
        locations: locations.data.entries.sort((a: any,b: any) => parseInt(a.index) - parseInt(b.index)),
        articles: articles.data.entries.sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id)),
        cards: services.data.entries,
        onlineservices: onlineservices.data.entries,
        testimonials: testimonials.data.entries.sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id))
      })
      hashScrollHandler()
    }
    fetchPage()

    const clickTabScript = document.createElement('script');
    clickTabScript.src = "/tool/clicktab.min.js?" + Date.now();
    clickTabScript.async = true;
    document.body.appendChild(clickTabScript);
  }, [language])
  useEffect(() => {
    hashScrollHandler()
  }, [window.location.hash])
  return(
  <div className="landing-page-content">
    <Hero data={data.hero} btn={true} language={language}/>
    <div className="quick-links">
      <div className="container container-fluid">
        {/* <h3>Quicklinks</h3> */}
        <div className="row between-xs">
          {data.onlineservices.map((service: any, idx: number) => <div className="col-xs-6 col-sm-6 col-md-3 center-xs quick-link" key={"online-service"+idx}><a href={service.link} className="center-xs">
            <img width="48" height="48" src={BaseURL + "/" +service.icon.path} alt=""/> <br />
            {service.name}
            </a></div>)}
        </div>
      </div>
    </div>
    <div className="container container-fluid">
      <div className="row">
        <h2 className="col-xs-12 col-md-6">{data.heading_1}</h2>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-8">
          <p dangerouslySetInnerHTML={{__html: data.paragraph_1}}/>
        </div>
        <div className="col-xs-12 col-sm-4">
          <img src={BaseURL + "/" + data.img_1.path} style={{width: '100%'}}  alt=""/>
        </div>
      </div>
      <div className="row main-card-row" id="services">
        {data.cards.map((card: any) => <MainCard {...card} language={language} key={card.title}/>)}
      </div>
    </div>
    <div className="hued-row" id="references">
      <div className="container container-fluid">
        <div className="row reference-row between-xs">
          <h2 className="col-xs-12">{data.heading_2}</h2>
          <div className="col-xs-12 col-md-6">
          <div className="row center-xs middle-xs">
            {data.img_2.map((img: any) => <img key={img.path} src={BaseURL + "/" + img.path} style={{padding: "10px 30px", filter: 'saturate(0)', opacity: 0.7}} className="col-xs-4 col-sm-3" alt=""/>)}
          </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <p dangerouslySetInnerHTML={{__html: data.paragraph_2}}/>
          </div>
        </div>
      </div>
    </div>
    <div className="container container-fluid" id="locations">
      <div className="row location-row">
        <h2 className="col-xs-12 center-xs">
          {(language==='de')||(language==='ch') ? "Unsere Standorte im Überblick" : ""}
          {language==='en' ? "Where to find us" : ""}
          {language==='fr' ? "Nos Emplacements" : ""}
          {language==='it' ? "Le Nostre Sedi" : ""}
        </h2>
            <div className="col-xs-12 col-md-6">
              <h3 className="location-heading-wrapper">
                {data.locations.map((location: any, idx: number) => (
                  <span 
                    className={idx===locationIdx ? "location-heading active" : "location-heading"}
                    onClick={() => setLocationIdx(idx)}
                    key={"location"+idx}
                    id={location.name.toLowerCase().replace(/[^a-zA-Z0-9_-]/g,'')}
                  >
                    {location.name}
                  </span>
                ))}
              </h3>
              <p><span dangerouslySetInnerHTML={{__html: data.locations[locationIdx].description}}/></p>
          <p className="location-info"> <img width="24" src={clock} alt=""/>  <span dangerouslySetInnerHTML={{__html: data.locations[locationIdx].openingHours}}/></p>
          <p className="location-info"> <img width="24" src={mail} alt=""/>  <a href={"mailto:"+ data.locations[locationIdx].mail}>{data.locations[locationIdx].mail}</a></p>
          <p className="location-info"> <img width="24" src={call} alt=""/>  <a href={"tel:"+ data.locations[locationIdx].tel.toString().replace(/\s/g, '').replace(/-/g, '')}>{data.locations[locationIdx].tel}</a></p>
          <p className="location-info"> <img width="24" src={poi} alt=""/>  <span dangerouslySetInnerHTML={{__html: data.locations[locationIdx].address}}/></p>
            </div>  
            <div className="col-xs-12 col-md-6 stretch-xs">
            <iframe title="Google Maps Embedded" className="gmaps" id="gmap_canvas" src={"https://maps.google.com/maps?q=setronics%20"+data.locations[locationIdx].name+"&t=&z=13&ie=UTF8&iwloc=&output=embed"} scrolling="no"></iframe>
            </div>  
      </div>
    </div>
    <div className="hued-row" id="news">
      <div className="container container-fluid">
        <div className="row reference-row between-xs">
            <h2 className="blue-heading col-xs-12">

            {(language==='de')||(language==='ch') ? "News" : ""}
            {language==='en' ? "News" : ""}
            {language==='fr' ? "Nouvelles" : ""}
            {language==='it' ? "Notizie" : ""}
            </h2>

            {data.articles.length && (width < 1000) ? <Article {...data.articles[Math.abs(articleIdx%data.articles.length)]} /> : <>
            <Article {...data.articles[Math.abs(articleIdx%data.articles.length)]} />
            <Article {...data.articles[Math.abs((articleIdx+1)%data.articles.length)]} />
            </>}

        </div>
        <div className="row center-xs">
          <button className="next-prev-btn" onClick={() => setArticleIdx(articleIdx-((width < 1000) ? 1 : 2))}> <img width="24" height="24" src={back} alt="<"/> </button>
          <button className="next-prev-btn" onClick={() => setArticleIdx(articleIdx+((width < 1000) ? 1 : 2))}> <img width="24" height="24" src={next} alt="<"/> </button>
        </div>
      </div>
    </div>
    <div className="container container-fluid" id="contact">
      <div className="row contact-row">
        <div className="col-xs-12 col-md-6">
          <h2 className="blue-heading">
            {(language==='de')||(language==='ch') ? "Kontakt" : ""}
            {language==='en' ? "Contact" : ""}
            {language==='fr' ? "Contact" : ""}
            {language==='it' ? "Contatto" : ""}
          </h2>
          <b>
            {(language==='de')||(language==='ch') ? "Interesse geweckt? Kontaktieren Sie uns!" : ""}
            {language==='en' ? "Did we spark your interest? Message us!" : ""}
            {language==='fr' ? "Intérêt suscité? Contactez nous." : ""}
            {language==='it' ? "Interesse suscitato? Contattaci!" : ""}
          </b>
          <form className="contact-form" id="contact-form">
            <div className="row">
              <div className="col-xs-6">  <input id="form-name" placeholder="Name" type="text"/></div>
              <div className="col-xs-6">  <input id="form-tel" placeholder="Tel" type="text"/></div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <input placeholder="E-Mail" id="form-email" type="text"/>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <textarea placeholder="Text" name="" id="form-content" cols={30} rows={10}></textarea>
              </div>
            </div>
            <input style={{color: "#fff", backgroundColor: "#0E6EB6", cursor: 'pointer'}} type="submit" onClick={e => sendData(e, language)} className="main-card-btn" value={
              (language==='de')||(language==='ch') ? "Senden" : 
              language==='en' ? "Send" : 
              language==='fr' ? "Envoyer" : 
              language==='it' ? "Inviare" : ""
            } /> 
          </form>
        </div>
        <div className="col-xs-12 col-md-6">
          <Testimonial testimonials={data.testimonials as ITestimonial[]}/>
        </div>
      </div>
    </div>
  </div>
  )
}

export default LandingPage