import React, { useState, useEffect } from 'react';
import { NavLink as Link } from 'react-router-dom'
import logo from '../images/logo_.png';
import ch_ico from '../images/ch.svg';
import de_ico from '../images/de.svg';
import axios from 'axios';
import {BaseURL, Token, hashScrollHandler} from '../utils/utils';

// import menu from '../images/menu.svg'

type callBack = (arg: string) => void;

const dehydrated = {
  locations:[{name: "", description: "", openingHours: "", mail: "", tel: "", maps: "", img: {path: ""}}]
} as any

const TopBar = ({setLang, language}: {setLang: callBack, language: string}) => {
  const host = window.location.host
  const [data, setData] = useState(dehydrated);
  const [visible, setVisible] = useState(false)
  const [country, setCountry] = useState(host.search('.ch') !== -1 ? 'ch' : 'de')

  const handleCountry = (country:any) => { 
    setCountry(country);
    if( window.location.host.indexOf("kaydeigmann") === -1 ) {
      if( country === "de" ) {
        window.location.href = window.location.href.replace(".ch", ".de").replace(".com", ".de");
      } else if( country === "ch" ) {
        window.location.href = window.location.href.replace(".de", ".ch").replace(".com", ".ch");
      }
    }
  };

  const handleLang = (lang:any) => { 
    setLang(lang);
    if( window.location.host.indexOf("kaydeigmann") === -1 ) {
      if( lang === "en" ) {
        window.location.href = window.location.href.replace(".ch", ".com").replace(".de", ".com");
      } else if( country !== "ch" && lang === "de" ) {
        window.location.href = window.location.href.replace(".ch", ".de").replace(".com", ".de");

      }
    }
  };

  

  useEffect(() => {
    async function fetchPage(){
      const locations = await axios.get(BaseURL + '/cockpit/api/collections/get/locations?token='+Token+'&lang='+language);
      setData({
        locations: locations.data.entries.sort((a: any,b: any) => parseInt(a.index) - parseInt(b.index))
      })
      const links = document.querySelectorAll("a");
      links.forEach(x => x.setAttribute("onclick", "hashScrollHandler()"));
      window.addEventListener("hashchange", e=>hashScrollHandler(), {});
    }
    fetchPage()

    if(country==='de'&&language!=='en'){
      setLang("de")
    }
    if(country==="ch"){
      setLang("ch")
    }
  }, [country])
  return(
  <div className="top-bar">
    <div className="container container-fluid" style={{paddingTop: 0, paddingBottom:0}}>
      <div className="row between-xs middle-xs">
        <Link className="logo-link" to='/'><span className="logo"><img src={logo} alt=""/></span></Link>
        <div className="hide-lg row" style={{paddingRight: "2rem"}}>
          <div style={{position: 'relative', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
          {country==='ch' ? 
            <img onClick={e => {setCountry('ch'); setLang('de')}} src={ch_ico} alt="" className="country-selector-ico"/>
          :
            <img onClick={e => {setCountry('de'); setLang('de')}} src={de_ico} alt="" className="country-selector-ico"/>
          }
          <select className="country-selector" style={{width:"28px", height: "28px", fontSize: "0", position:'absolute', left: 0}} onChange={e => handleCountry(e.target.value)}>
              <option style={{fontSize: '1rem'}} selected={country==='de'} value="de">Deutschland</option>
              <option style={{fontSize: '1rem'}} selected={country==='ch'} value="ch">Schweiz </option>
          </select> 
          </div>
          <div style={{marginLeft: "28px"}}  onClick={() => setVisible(!visible)} className={"hamburger" + (visible ? " is-active" : "")} id="hamburger-1">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
        {visible ? <div className="abs-nav">
          <nav>
            <ul className="nav-ul-condensed hide-lg">
              <li>
                <Link onClick={() => setVisible(false)} to="/services/professional">Professional Services</Link>
              </li>
              <li>
                <Link onClick={() => setVisible(false)} to="/services/consumer">Consumer Services</Link>
              </li>
              <li>
                <Link onClick={() => setVisible(false)} to="/services/it">IT Services</Link>
              </li>
              <li>
                <Link onClick={() => setVisible(false)} to="/shops">Webshops</Link>
              </li>
              <li>
                <Link onClick={() => setVisible(false)} to="/help-point">Help Point</Link>
              </li>
              <li>
                <a href="https://jobs.sertronics.de">
                  {(language==='de')||(language==='ch') ? "Karriere" : ""}
                  {language==='en' ? "Career" : ""}
                  {language==='fr' ? "Carrière" : ""}
                  {language==='it' ? "Carriera" : ""}
                </a>
              </li>
              {/* <li>
                <Link onClick={() => setVisible(false)} to="/career">
                  {(language==='de')||(language==='ch') ? "Karriere" : ""}
                  {language==='en' ? "Career" : ""}
                  {language==='fr' ? "Carrière" : ""}
                  {language==='it' ? "Carriera" : ""}
                </Link>
              </li> */}
              {/* <hr/> */}
              {/* <li style={{display: 'flex', alignItems: 'center'}}> */}
              {/* {country === 'ch' ? <img src={ch_ico} alt="" style={{width: "16px", height: "16px", marginRight: "16px"}}/> : <img src={de_ico} alt=""  style={{width: "16px", height: "16px", marginRight: "16px"}}/>}  
              <select onChange={e => setCountry(e.target.value)}>
              <option  value="de">Deutschland</option>
              <option value="ch">Schweiz</option>
            </select> */}

              {/* </li> */}

            <div className="lang-selector-abs">
            
            {country === 'de' ? 
              <>{["DE","EN"].map(lang => <button key={"lang-btn-"+lang} className={(lang.toLocaleLowerCase() === language ? " lang-btn-selected-abs" : "lang-btn lang-btn-abs")} onClick={() => {handleLang(lang.toLocaleLowerCase())}}>{lang}</button>)}</>
            : null}
            {country === 'ch' ? 
              <>{["DE", "FR"].map(lang => <button key={"lang-btn-"+lang} className={(lang.toLocaleLowerCase() === (language==='ch' ? 'de' : language) ? " lang-btn-selected-abs" : "lang-btn lang-btn-abs")} onClick={() => {
                if(lang==='DE'){handleLang('ch')}else{handleLang(lang.toLocaleLowerCase())}
              }}>{lang}</button>)}</>
            : null}
          </div>
            </ul>
          </nav>
        </div>
        : null}
        <div className="nav-right hide-sm">
          <nav>
            <ul className="nav-ul-normal">
              <li id="nav-services">
                <Link to="/services/">Services</Link>
                <div className="hover-menu">
                  <Link to="/services/professional">Professional Services</Link>
                  <Link to="/services/consumer">Consumer Services</Link>
                  <Link to="/services/it">IT-Services</Link>
                </div>
              </li>
              <li id="nav-shops">
                <Link to="/shops">Webshops</Link>
                <div className="hover-menu">
                  <a target="_blank" href="https://www.berrybase.de" rel="noopener noreferrer">Berrybase-Shop</a>
                  <a target="_blank" href="https://et.sertronics-shop.de" rel="noopener noreferrer">
                    {(language==='de')||(language==='ch') ? "Ersatzteile-Shop" : ""}
                    {language==='en' ? "Spare Parts Shop" : ""}
                    {language==='fr' ? "Pièces de rechange" : ""}
                    {language==='it' ? "Pezzi di ricambio" : ""}
                  </a>
                </div>
              </li>
              <li id="nav-help-point">
                <Link to="/help-point">Help Point</Link>
                <div className="hover-menu">
                  <a href="/help-point#repair_booking">
                    {(language==='de')||(language==='ch') ? "Reparaturannahme" : ""}
                    {language==='en' ? "Repair Booking" : ""}
                    {language==='fr' ? "Réparation d'appareils" : ""}
                  </a>
                  <a href="/help-point#repair_info">
                    {(language==='de')||(language==='ch') ? "Reparaturauskunft" : ""}
                    {language==='en' ? "Repair Tracking" : ""}
                    {language==='fr' ? "Informations de réparation" : ""}
                  </a>
                  <a href="/help-point#repair">
                    {(language==='de')||(language==='ch') ? "Preisliste" : ""}
                    {language==='en' ? "Price List" : ""}
                    {language==='fr' ? "Liste de prix" : ""}
                  </a>
                  <a href="/help-point#retour">
                    {(language==='de')||(language==='ch') ? "Retourenportal" : ""}
                    {language==='en' ? "Return portal" : ""}
                  </a>
                </div>
              </li>
              <li id="nav-locations">
                <a href="/#locations">
                  {(language==='de')||(language==='ch') ? "Standorte" : ""}
                  {language==='en' ? "Locations" : ""}
                  {language==='fr' ? "Emplacements" : ""}
                  {language==='it' ? "Posizioni" : ""}
                </a>
                <div className="hover-menu">
                  {data.locations.map((location: any, idx: number) => (
                    <a href={"/#locations_" + location.name.toLowerCase().replace(/[^a-zA-Z0-9_-]/g,'')}>{location.name}</a>
                  )
                  )}
                </div>
              </li>
              <li>
                <a href="https://jobs.sertronics.de">
                  {(language==='de')||(language==='ch') ? "Karriere" : ""}
                  {language==='en' ? "Career" : ""}
                  {language==='fr' ? "Carrière" : ""}
                  {language==='it' ? "Carriera" : ""}
                </a>
              </li>
              { /*<li>
                <Link to="/career">
                {(language==='de')||(language==='ch') ? "Karriere" : ""}
                {language==='en' ? "Career" : ""}
                {language==='fr' ? "Carrière" : ""}
                {language==='it' ? "Carriera" : ""}
                </Link>
                </li> */}
            </ul>
          </nav>
          <span className="separator">|</span>
          <div className="lang-selector">
          <div className="country-select-wrapper">
            {country === 'ch' ? <img className="country-selector-ico" src={ch_ico} alt=""/> : <img className="country-selector-ico" src={de_ico} alt=""/>}
            <select className="country-selector" onChange={e => handleCountry(e.target.value)}>
              <option selected={country==='de'} value="de">Deutschland </option>
              <option selected={country==='ch'} value="ch">Schweiz </option>
            </select> 
            {/* <img onClick={e => {setCountry('ch'); setLang('de')}} src={ch_ico} alt="" className={"country-selector-ico " + (country==='ch' ? 'active' : '')}/>
            <img onClick={e => {setCountry('de'); setLang('de')}} src={de_ico} alt="" className={"country-selector-ico " + (country==='de' ? 'active' : '')}/> */}
            </div>
          {country === 'de' ? 
            <>{["DE","EN"].map(lang => <button key={"lang-btn-"+lang} className={(lang.toLocaleLowerCase() === language ? " lang-btn-selected" : "lang-btn")} onClick={() => {handleLang(lang.toLocaleLowerCase())}}>{lang}</button>)}</>
          : null}
          {country === 'ch' ? 
            <>{["DE","FR"].map(lang => <button key={"lang-btn-"+lang} className={(lang.toLocaleLowerCase() === (language==='ch' ? 'de' : language) ? " lang-btn-selected" : "lang-btn")} onClick={() => {
              if(lang==='DE'){handleLang('ch')}else{handleLang(lang.toLocaleLowerCase())}
            }}>{lang}</button>)}</>
          : null}
          </div>
        </div>
      </div>
    </div>
  </div>
)}

export default TopBar