// export const BaseURL = process.env.NODE_ENV === "development" ? "https://chweb.avc.de/" : "/"
// export const BaseURL = process.env.NODE_ENV === "development" ? "http://134.209.230.215" : ""
export const BaseURL = getBaseURL();
export const Token = "5857565824a7c3709967724b25b797"

//const delay = async (ms: number) => new Promise(res => setTimeout(res, ms));

function getBaseURL() {
  var baseURL = (process.env.NODE_ENV === "development" ? "//sertronics.kaydeigmann.de" : "..")
  var pathnameArr = window.location.pathname.split("/");
  var loc = pathnameArr.slice(-1)[0]||"default"
  if( loc.indexOf(".html") != -1 && pathnameArr.length == 5 ) {
    baseURL = "../../..";
  }
  return baseURL;
}

export const hashScrollHandler = (id?: any) => {
  (window)["hashScrollHandler"](id)
  /*setTimeout(() => {
    if(window.location.hash !== ""){
      let el = null
      let tab = null

      if(id){
        el = document.getElementById(id)
      }else{
        let hashName = window.location.hash.split("#")[1];
        el = document.getElementById(hashName);
        if( el == null && hashName.indexOf("_") != -1 ) {
          tab = hashName.split("_")[1];
          el = document.getElementById(tab);
        }
      }
      if(el){
        let elPos = el.getBoundingClientRect().top
        let bodyPos = document.body.getBoundingClientRect().top
        elPos = elPos - bodyPos - 75
        window.scrollTo({
          top: elPos,
          behavior: "smooth"
        });

        if(tab) {
          var tabs = document.getElementsByClassName('location-heading');
          for(var i=0;i < tabs.length;i++) {
              if(tabs[i].id.toLowerCase() == tab.toLowerCase()) {
                  (window as { [key: string]: any})["clickTab"](tab);
              } 
          }
        }
      }
    }
  }, 100)*/
}