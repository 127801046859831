import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Hero} from '../components/Hero';
import {BaseURL, Token} from '../utils/utils';

const dehydrated = {
    hero: [],
    shops: [],
    contacts: []
}

const Shops = ({language}: {language: string}) => {
  const [data, setData] = useState(dehydrated)
  useEffect(() => {
    async function fetchPage() {
      const content = await axios.get(BaseURL + '/cockpit/api/singletons/get/webshops?token='+Token+'&lang='+language+'&populate=3');
      setData({...dehydrated, ...content.data})
    }
    fetchPage()
  }, [language])
  return(
  <div className="shop-content">
    <Hero data={data.hero} btn={false} language={language}/>
    <div className="container container-fluid">
      <h2 className="blue-heading">Webshops</h2>
      {data.shops.map((shop: any, idx) => 
        <div key={"shop-"+idx} className={idx%2===0 ? "row shop-row" : "row shop-row reverse"}>
          <div className="col-xs-12 col-md-6">
            <h2>{shop.title}</h2>
            <p>{shop.text}</p>
            <a target="_blank" href={shop.link} rel="noopener noreferrer"><button style={{marginBottom: '20px'}} className="main-card-btn">
              {shop.link_text}
            </button></a>
          </div>
          <div className="col-xs-12 col-md-6 center-xs start-md">
            <br/>
            <img src={BaseURL + "/" + shop.img.path} style={{width: 'auto', maxHeight: "236px"}} alt=""/>
          </div>
        </div>
      )}
    </div>
    <div className="hued-row">
    <div className="container container-fluid">
      <h3 className="blue-heading"> {(language==='de')||(language==='ch') ? "Kontakt" : ""}</h3>
      <h2>              {(language==='de')||(language==='ch') ? "Ansprechpartner" : ""}
              {language==='en' ? "Contact" : ""}
              {language==='fr' ? "Contact" : ""}
              {language==='it' ? "Contatto" : ""}</h2>
      <div className="row center-xs">
        {data.contacts.map((contact: any) => (
          <div key={"contact"+contact.name} className="col-xs-12 col-sm-6 col-md-4 contact-card-wrapper">
            <div className="contact-card start-xs">
            <div className="contact-upper">
              <img src={BaseURL + "/" + contact.img.path} alt=""/>
              <div>
                <b>{contact.name}</b>
                <div>{contact.title}</div>
              </div>
            </div>
            <div>
              <div>Mail: <a href={"mailto:"+ contact.mail}>{contact.mail}</a></div>
              <div>Tel: <a href={"tel:"+ contact.tel.toString().replace(/\s/g, '').replace(/-/g, '')}>{contact.tel}</a></div>
            </div>
            </div>
          </div>
        ))}
        
      </div>
    </div>
    </div>
  </div>
  )
}

export default Shops